import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import {gsap} from "gsap";

export default class ContentWrapper extends ComponentBase
{
  private _height: number;
  private _$wrapper: JQuery;

  constructor(elem: ComponentElem)
  {
    super(elem);

    this._$wrapper = this.$root.find(">.wrapper");    

    if(this.$root.attr("index") !== undefined) 
    {
      this.toIndex(parseInt(this.$root.attr("index")), false);
    }
  }

  toContent(targetQuery: string, tweenIt: boolean = true)
  {
    if(tweenIt) this.freeze();

    this._$wrapper.find(">*").attr("is-hidden", "true");
    let $new = this._$wrapper.find(">" + targetQuery).attr("is-hidden", "false");

    // console.log($new.toArray());
    // gsap.from($new, {duration: .3, opacity: 0, clearProps: "all"});
    
    if(tweenIt) this.unfreeze();    
  }

  toIndex(index: number, tweenIt: boolean = true)
  {
    this.toContent(`*:nth-child(${index})`, tweenIt);
  }

  freeze(opacity = 1)
  {
    this._height = this.$root.height();
    gsap.set(this.$root, {height: this._height, opacity: opacity});
  }

  unfreeze()
  {
    let targetHeight = this._$wrapper.height();    
    gsap.killTweensOf(this.$root);
    gsap.to(this.$root, {height: targetHeight, opacity: 1, clearProps: "all", ease: "power3.out"});
  }
}