import PatternSamples from "@/src/scripts/ts/PatternSamples";
import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import { Common } from "@root/components/common/CommonTypes";
import Tools from "@root/ts/Tools";
import { Emitter } from "strict-event-emitter";


/**
 * 資料欄位組件
 * 
 * 根據其 data-field 和 data-group 屬性產生 data-field-query 屬性以供快速檢索
 *
 * @export
 * @class Field
 * @extends {ComponentBase}
 * @implements {Common.FormField}
 * @implements {Common.CollapseRelational}
 */
export default class Field extends ComponentBase implements Common.FormField, Common.CollapseRelational
{
  // static setupFields($container:  JQuery)
  // {
  //   let fieldElemList = $container.find(`[component="field"]`).toArray();
  //   fieldElemList.forEach((elem: ComponentElem)=>
  //   {
  //     new Field(elem);
  //   });
  // }

  inputComponent: Common.InputComponent;
  emitter: Emitter<{ change: [value: string] }>;

  constructor(elem: ComponentElem)
  {
    super(elem);

    if (elem.dataset.field) {
      let id = `[${elem.dataset.field}]`;
      if (elem.dataset.group) id += `[${elem.dataset.group}]`

      // console.log(id);
      elem.dataset.fieldQuery = id;
    }


    this.emitter = new Emitter();

    let myType = this.$root.attr("type"),
      component: Common.InputComponent;

    if (myType === "country-select" || myType === "international-call") {
      this.$root.find(".iti").find("div").attr("tabindex", "-1");
    }

    // if(myType === "password") 
    // {
    //   myType = "input";
    // }

    if (myType === "country-select" || myType === "input" || myType === "textarea" || myType === "password" || myType === "international-call" || myType === "select" || myType === "date-input" || myType === "vcode-input") {

      let t = myType === "password" ? "input" : myType;
      component = this.inputComponent = (this.$root.find(`[component="${t}"]`)[0] as ComponentElem)._component as Common.InputComponent;
      let pattenName = this.$root.attr("pattern");

      // console.log(this.$root[0]);
      // console.log(component);

      if (component) {
        if (pattenName && component.changePattern) component.changePattern(pattenName);

        if (myType === "password") this.setupPasswordType();


        component.emitter.on("change", (value, isOk) =>
        {
          let okState = myType === "password" ? "normal-ok" : "ok";
          this.$root.attr("state", isOk ? okState : "normal");
          this.emitter.emit("change", value);
        });

        component.update();
      }
      else {
        console.error(`can't find input component for `, this.$root[0], "maybe input type is not imported");
      }
    }
    else {

      // component = this.inputComponent = (this.$root.find(`[component="${myType}"]`)[0] as ComponentElem)._component as Common.InputComponent;
      console.log(`未處理類別[${myType}]`);
    }
  }

  setupPasswordType()
  {
    let isHidden = true,
      $input = this.$root.find("input"),
      $endIcon = this.$root.find(".end-icon").on("click", (event: JQuery.ClickEvent) =>
      {
        event.preventDefault();
        isHidden = !isHidden;

        $endIcon.attr("eye-open", isHidden ? "false" : "true");
        $input.attr("type", isHidden ? "password" : "text");

        // this.$root.attr("state", "normal");
      });
  }

  reset()
  {
    if (this.inputComponent) {
      this.inputComponent.reset();
    }

  }

  toggleError(errorOn: boolean, message?: string)
  {

    if (errorOn) {
      if (!message && !this.inputComponent.isOk()) {
        message = this.inputComponent.getValue().length === 0 ? "此欄位必填" : "資料格式錯誤";
      }
      this.$root.attr("state", "error").find(".warning").text(message);
    }
    else {
      this.inputComponent.update();
    }
  }

  isOptional()
  {
    return !(this.$root.attr("is-optional") === "false");
  }

  getValue(): any
  {
    let value: any = this.inputComponent.getValue();
    if (this.$root.attr("pattern") === "number") {
      return this.inputComponent.isOk() ? Tools.parseFloat(value) : undefined;
    }
    else {
      return this.inputComponent.isOk() ? value : "";
    }
  }

  getDataField()
  {
    return this.$root[0].dataset.field;
  }

  getDataGroup()
  {
    return this.$root[0].dataset.group;
  }
}