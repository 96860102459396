import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import IntroCanvas from "./IntroCanvas";
// import IntroCanvas from "./TestCanvas";
import ViewportRoot from "../../ts/sframe/ViewportRoot";
import Geom from "../../ts/sframe/Geom";
import { ScrollListener } from "../../ts/sframe/ScrollListener";
import Utility from "../../ts/sframe/Utility";
import Tools from "../../ts/Tools";
import gsap from "gsap";

export default class Intro extends ComponentBase
{
  private _introCanvas: IntroCanvas;

  private _$container: JQuery;

  private _isInsideViewport = true;

  private _tl: gsap.core.Timeline;

  constructor(elem: ComponentElem)
  {
    super(elem);

    // return;
    
    this._introCanvas = new IntroCanvas(true, false);
    this.$root.prepend(this._introCanvas.canvas);

    this._$container = this.$root.find(".container");
    
    this._setupResize();
    this._setupAnimate();
    this._setupScrolldownHint();

    ScrollListener.addListener("intro-test", ()=>
    {
      let bound = this.$root[0].getBoundingClientRect();
      // console.log(bound);
      this._introCanvas.isInViewport = (bound.bottom > 0 || bound.top > ViewportRoot.height);
    });

  }

  private _setupScrolldownHint()
  {
    let $hint = this.$root.find(`[component="scrolldown-hint"]`);
    
    ScrollListener.addListener("intro-scrolldown-hint", (p: any)=>
    {
      $hint.attr("hiding", p.top > 0? "true": "false");
    });
    
  }

  private _setupModeChange()
  {
    this.$root.on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      this._introCanvas.toNextMode();      
    });
  }

  private _setupResize()
  {
    this._introCanvas.bufferShader2.changeMode(ViewportRoot.index === 0? "mobile": "pc");

    ViewportRoot.emitter.on("updated", (vp)=>
    {
      let width = this.$root.width(),
        height = this.$root.height();
      // let width = vp.width,
      //   height = vp.height;
      // console.log(`width: ${width}, height: ${height}`);
      if(vp.isMajorChange)
      {
        this._introCanvas.bufferShader2.changeMode(ViewportRoot.index === 0? "mobile": "pc");
      }
      
      
      let contentWidth = 1900,
        contentHeight = 930;

      if(vp.index === 0)
      {
        contentWidth = 640;
        contentHeight = 1020;
      }

      let bound = Geom.caculateContain(width, height, contentWidth, contentHeight);

      let $bound = this.$root.find(".intro-bound");

      // console.log(bound.ratio);
      

      this._$container.css({
        "transform": `scale(${bound.ratio})`
      });

      $bound.css({
        marginLeft: -bound.width*.5,
        marginTop: -bound.height*.5,
        width: bound.width,
        height: bound.height
      });
      
      this._introCanvas.resize(width, height, bound.ratio, vp.index);
    });
  }

  

  private _setupAnimate()
  {
    if(Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      start: "top center",
      // markers: true
    };

    if (Utility.urlParams.test === "animation") trigger = undefined;

    

    let chars: { [key: string]: HTMLElement[] } =
    {
      text1: Tools.splitText(this.$root.find(".text-1")[0]),
      // text2: Tools.splitText(this.$root.find(".text-2")[0]),
      // text3: Tools.splitText(this.$root.find(".text-3")[0]),
    };

    let tl;

    gsap.set(this.$root, {opacity: 0});

    tl = this._tl = gsap.timeline({scrollTrigger: trigger});

    tl.set(chars.text1, {y: 50, opacity: 0});
    tl.set(q(".text-2"), {opacity: 0, filter: "blur(20px)"});
    tl.set(q(".text-3"), {opacity: 0, filter: "blur(20px)"});
    tl.set(this._introCanvas.canvas, {opacity: 0});
    // tl.set(this._introCanvas.uniforms.uBeamPower, {value: 0});
    // tl.set(chars.text2, {x: -50, y: -100, opacity: 0});
    // tl.set(chars.text3, {x: -50, y: -100, opacity: 0});

    tl.to(this.$root, {duration: .05, opacity: 1});

    tl.to(chars.text1, { duration: .7, opacity: 1, x: 0, y: 0, stagger: .02, ease: "power3.out" }, "start");
    tl.to(q(".text-2"), {duration: .6, opacity: 1, filter: "blur(0px)", ease: "none"}, "start+=.6");
    tl.to(q(".text-3"), {duration: .6, opacity: 1, filter: "blur(0px)", ease: "none"}, "start+=.7");
    tl.to(this._introCanvas.canvas, {duration: .5, opacity: 1, ease: "power1.in"}, "start+=.3");
    
    tl.addLabel("beam-in", "-=.6");

    tl.add(()=>
    {
      this._introCanvas.toFirstMode();
      this._setupModeChange();
    });
    //tl.to(this._introCanvas.uniforms.uBeamPower, {duration: .6, value: 1, ease: "power3.in"}, "beam-in");
    
    tl.seek("start").pause();

    tl.scrollTrigger.disable();


    // 混合動畫
    // mainTl = gsap.timeline({ scrollTrigger: trigger });
    // // mainTl.set(q(".graphic-part"), { opacity: 0 });
    // mainTl.addLabel("start", .01);
    // mainTl.add(tl1, 0);
    // // mainTl.addLabel("tl2-in", "-=0.5");

    // mainTl.add(() =>
    // {
    //   // console.log("end");
    // }, "+=.5");

    // tl2.seek("start").pause();

    // // 測試用
    // if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, tl1, "start");
  }

  ready()
  {
    this._introCanvas.start();
    this._tl.scrollTrigger.enable(true, true);
  }
}