import { Vector4 } from "three";
import BufferShader from "../../ts/BufferShader";
import fragSource from "@shaders/smoothballs.frag";
// import fragSource from "@shaders/test12.frag";

const Sphere = 
{
  "pc": [
    new Vector4(-1.7, -1.4, .0, 1.0),
    new Vector4(1.4, 1.2, .0, .4),
    new Vector4(-.8, .9, .0, .2),
    new Vector4(.3, .6, .0, .05),
  ],

  "mobile": [
    new Vector4(0),
    new Vector4(0),
    new Vector4(0),
    new Vector4(0),
  ]
}

export default class IntroScene2Buffer extends BufferShader
{
  constructor(renderer: THREE.WebGLRenderer, size: THREE.Vector2)
  {
    super(renderer, size);
    
    this.build(fragSource, {
      sphere:{
        value: Sphere["pc"]
      },
      isMobile:{
        value: false
      }
    });
  }

  changeMode(mode: "pc" | "mobile")
  {
    // this.uniforms.sphere.value = Sphere[mode];
    this.uniforms.isMobile.value = mode === "mobile";
  }
}