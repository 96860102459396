import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Tools from "../../ts/Tools";
import Components from "../Compoents";
import RwdPicture from "../RwdPicture";
import { MemberThumbData } from "./TeamMembers";

export default class MemberThumb extends ComponentBase
{
  static generate(data: MemberThumbData)
  {
    return new MemberThumb(MemberThumb.createElem(), data);
  }

  constructor(elem: ComponentElem, data?: MemberThumbData)
  {
    super(elem);
    
    if(data)
    {
      this.$root.find(".job-title").html(data.job_title);
      this.$root.find(".name").html(data.name);

      Components.bindScripts(elem);
      
      let rp = Tools.seekComponentByClass("image", this.$root) as RwdPicture;
      rp.setDataSrc(data.image.pc, "pc");
      rp.setDataSrc(data.image.mobile, "mobile");
      rp.load();
    }
  }
}