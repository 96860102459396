import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import FormTools from "../../ts/FormTools";
import Tools from "../../ts/Tools";
import RadioSelect from "../ui/checkboxs/RadioSelect";
import EZAlert from "../popups/EZAlert";
import ContentWrapper from "../ui/ContentWrapper";
import ApiProxy from "../../ts/ApiProxy";
import schema from "@schema/index/contact-us.json";

export default class Footer extends ComponentBase
{
  private _radioSelect: RadioSelect;

  constructor(elem: ComponentElem)
  {
    super(elem);
    
    this._radioSelect = new RadioSelect(this.$root.find(".select-service")[0] as ComponentElem);
    // console.log(this._radioSelect);

    let contentWrapper: ContentWrapper = this.seekComponentByName("content-wrapper");

    this.$root.find(".btn-to-form").on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      contentWrapper.toContent(".details");
    });
    
    
    this.$root.find(".btn-send").on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      
      
      
      let result = FormTools.getFormData(this.$root, true);
      result.formData.service = this._radioSelect.getValue() || [];
      
      if(result.isReady)
      {
        EZAlert.pending(true);

        fetch("https://www.hikaru.com.tw/pms/crontab/send_contactus_form_data", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(result.formData)
        })
          .then((response) => response.json())
          .then( (res)=>
          {
            EZAlert.pending(false, ()=>
            {

              if (res.error && res.error !== "")
              {
                // console.log(res.error);
                
                EZAlert.alert(res.error);
              }
              else
              {
                EZAlert.alert("資料成功送出").then(()=>
                {
                  contentWrapper.toContent(".description");
                  this.reset();
                });
              }
            });
          })
          .catch(function (err)
          {
            EZAlert.alert("Error!");
          });


        /*
        // ApiProxy.callApi("index/contact-us", result.formData, { schema: schema, useApiSchemaData: true }).then((res) =>
        ApiProxy.callApi("send_contactus_form_data", result.formData, {}).then((res) =>
        {
          EZAlert.alert("資料成功送出").then(()=>
          {
            contentWrapper.toContent(".description");
            this.reset();
          });
        }).catch((error) =>
        {
          EZAlert.alert(error.message);
        });
        */
      }
      else
      {
        // EZAlert.alert("送出前，請先完成必填(紅色*號)項目");
        Tools.shake(result.firstErrorElem);
      }
      // console.log(result.formData);
      console.log(result);
    });

    this.reset();
  }

  reset()
  {
    FormTools.resetFields(this.$root);
    let rs: RadioSelect = this.seekComponentByName("radio-select");
    rs.reset();
  }
}