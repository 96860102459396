import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import ApiProxy from "../../ts/ApiProxy";
import MemberThumb from "./MemberThumb";
import Utility from "../../ts/sframe/Utility";
import gsap from "gsap";
import Tools from "../../ts/Tools";
import ViewportRoot from "../../ts/sframe/ViewportRoot";
import GS from "../../ts/GlobalSetting";
import Geom from "../../ts/sframe/Geom";


export type MemberThumbData = {
  image: {
    pc: string,
    mobile: string,
  },

  job_title: string,
  name: string
}

export default class TeamMembers extends ComponentBase
{
  private _dateList: MemberThumbData[];
  private _thumbList: MemberThumb[];

  private _params =
    {
      contentWidth: 0,
      containerWidth: 0,
      dx: 0,
      offsetLeft: 0
    };

  constructor(elem: ComponentElem)
  {
    super(elem);

    this._setupResize();
    this._setupContents();
    this._setupAnimate();
  }

  private _setupResize()
  {
    if (Utility.urlParams.test === "animation" || Utility.urlParams.test === "layout")
    {
      this.$root.attr("test-for-animation", "true");
    }

    // if (Utility.urlParams.test === "animation") return;

    let $contentWrapper = this.$root.find(">.content>.wrapper");

    ViewportRoot.emitter.on("updated", (vp) =>
    {
      let bound = Geom.caculateContain(vp.width - GS.getNavSize().width, vp.height - GS.getNavSize().height, 1836, 930);
      // console.log(bound.ratio);
      let ratio = bound.ratio;
      ratio = Math.min(1, ratio);

      // console.log(ratio);
      
      if(ViewportRoot.index === 0)
      {
        this.$root.css({
          "font-size": ""
        });
      }
      else
      {
        this.$root.css({
          "font-size": (GS.EM_TO_PX * ratio) + "px"
        });
      }

      
      this._params.containerWidth = vp.width - GS.getNavSize().width;
      this._params.contentWidth = $contentWrapper.width();
      this._params.offsetLeft = parseInt($contentWrapper.css("left"));

      this._params.dx = this._params.contentWidth - (this._params.containerWidth - this._params.offsetLeft);

      // console.log(this._params.contentWidth);
    });

  }

  private _setupAnimate()
  {
    if (Utility.urlParams.test === "layout") return;

    let self = this,
      q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      // trigger: this.$root.find(".content")[0],
      start: "top 50%",
      end: "bottom 100%",
      markers: false,
      scrub: 1
    };
    

    if (Utility.urlParams.test === "animation") trigger = undefined;

    let tl, tl1, tl2, mainTl,
      tweenObj = { progress: 0 };

    tl = tl1 = gsap.timeline();

    let chars: { [key: string]: HTMLElement[] } =
    {
      title: Tools.splitText(this.$root.find(".left-part>.title")[0]),
      subtitle: Tools.splitText(this.$root.find(".left-part>.subtitle")[0]),
      title2: Tools.splitText(this.$root.find(".section-description>.title")[0]),
      subtitle2: Tools.splitText(this.$root.find(".section-description>.subtitle")[0]),
    };
    

    let $thumbWrappers = this.$root.find(`[component="member-thumb"]>.wrapper`),
      $contentWrapper = this.$root.find(">.content>.wrapper");

    tl.set(chars.title, { opacity: 0, x: 100 });
    tl.set(chars.subtitle, { opacity: 0, transformOrigin: "center bottom", scaleY: 0, y: 20 });
    
    tl.set(chars.title2, { opacity: 0, x: 100 });
    tl.set(chars.subtitle2, { opacity: 0, transformOrigin: "center bottom", scaleY: 0, y: 20 });

    tl.set(q(".line"), { height: "0%" });
    tl.set($thumbWrappers, { width: "0%" });
    tl.set(tweenObj, {progress: 0, onUpdate: updateOffset});
    // tl.add(()=>
    // {
    //   tweenObj.progress = 0;
    //   updateOffset();
    // });

    updateOffset();


    tl.addLabel("start", .01);

    tl.to(chars.title, { duration: .4, opacity: 1, x: 0, stagger: .03, ease: "power1.out" }, "start");
    tl.to(chars.title2, { duration: .4, opacity: 1, x: 0, stagger: .03, ease: "power1.out" }, "start");

    tl.addLabel("subtitle-in", "-=.3")
    tl.to(chars.subtitle, { duration: .4, scaleY: 1, opacity: 1, y: 0, stagger: .02, ease: "back.out" }, "subtitle-in");
    tl.to(chars.subtitle2, { duration: .4, scaleY: 1, opacity: 1, y: 0, stagger: .02, ease: "back.out" }, "subtitle-in");
    

    // console.log(tl.duration()); // 1.18
    tl.addLabel("thumb-in", "-=.7");
    
    tl.addLabel("content-offset", "+=.3");
    
    tl.to($thumbWrappers, { duration: .3, width: "100%", stagger: .2, ease: "none" }, "thumb-in");
    tl.to(tweenObj, {duration: this._dateList.length*.5, progress: 1, ease: "none", onUpdate: updateOffset}, "content-offset");

    
    tl.to(q(".line"), { duration: .5, height: "100%", stagger: .1, ease: "none" }, "start");


    function updateOffset() {
      
      let tx = self._params.dx * tweenObj.progress;
      gsap.set($contentWrapper[0], {marginLeft: -tx});
    }

    // console.log(tl.duration()); // 4.98


    // 混合動畫
    mainTl = gsap.timeline({ scrollTrigger: trigger });
    mainTl.addLabel("start", .01);
    mainTl.add(tl1, 0);

    mainTl.add(()=>{}, "+=.5");

    mainTl.seek("start").pause();

    // 測試用
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, mainTl, "start");

  }

  private _setupContents()
  {
    this._dateList = ApiProxy.getStoredData("index/get-team-member-list").list;
    this._thumbList = [];

    let $container = this.$root.find(".right-part").empty(),
      $lineContainer = this.$root.find(".light-gray-lines");

    this._dateList.forEach((data, index) =>
    {
      let thumb: MemberThumb = MemberThumb.generate(data);
      $container.append(thumb.$root);

      let elem = document.createElement("div");
      elem.className = "line";

      $lineContainer.append(elem);

    });
  }
}