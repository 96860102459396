import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import { Emitter } from "strict-event-emitter";

export default class Pager extends ComponentBase
{
  private _maxSize: number = 5;
  setMaxSize(num: number)
  {
    this._maxSize = num;
    return this;
  }

  $btnPrev: JQuery;
  $btnNext: JQuery;

  totalCount: number;
  pageSize: number;
  startIndex: number;

  numPages: number;
  pageIndex: number;

  _isLocking: boolean = false;

  emiiter: Emitter<{change: [pageIndex: number]}>;

  private _start: number;
  private _end: number;

  private _queryUrl: string;

  private _useNumber: boolean = true;
  setUseNumber(b: boolean)
  {
    this._useNumber = b;
    return this;
  }

  private _manualChange: boolean = false; // true 的話, 點擊按鈕後, 手動執行 toIndex
  setManualChange(b: boolean)
  {
    this._manualChange = b;
    return this;
  }

  constructor(elem: ComponentElem)
  {
    super(elem);     

    this.emiiter = new Emitter();

    this.$btnPrev = this.$root.find(".btn-prev");
    this.$btnNext = this.$root.find(".btn-next");

    this.$btnPrev.on("click", (event:Event)=>
    {
      event.preventDefault();
      if(this._isLocking) return;

      // let newPageIndex = this.pageIndex - 1;
      let newPageIndex = this._start - 1;
      this.toIndex(newPageIndex, true);

      this.emiiter.emit("change", newPageIndex);
    });

    this.$btnNext.on("click", (event:Event)=>
    {
      event.preventDefault();
      if(this._isLocking) return;
      
      // let newPageIndex = this.pageIndex + 1;
      let newPageIndex = this._end + 1;
      this.toIndex(newPageIndex, true);
      
      // this.emiiter.emit("change", newPageIndex);      
    });
  }

  getPrevPageIndex()
  {
    if(this.numPages === 0) return undefined;
    let newPageIndex = this.pageIndex - 1;
    if(newPageIndex < 0) return undefined;
    return newPageIndex;
  }

  toPrevPage(fireEvent: boolean = true)
  {
    let index = this.getPrevPageIndex();
    if(index !== undefined) this.toIndex(index, fireEvent);
  }

  getNextPageIndex()
  {
    if(this.numPages === 0) return undefined;
    let newPageIndex = this.pageIndex + 1;
    if(newPageIndex >= this.numPages) return undefined;
    return newPageIndex;
  }

  toNextPage(fireEvent: boolean = true)
  {
    let index = this.getNextPageIndex();
    if(index !== undefined) this.toIndex(index, fireEvent);
  }

  toggleLocking(b: boolean)
  {
    this._isLocking = b;
  }

  setQueryUrl(queryUrl: string)
  {
    this._queryUrl = queryUrl;

    let $btns = this.$root.find(".btn").toArray();

    $btns.forEach((elem)=>
    {
      let index = elem.dataset.index;
      if(index !== undefined) elem.setAttribute("href", this._queryUrl + "&page-index=" + index);
    });
  }

  reset(pageSize: number, totalCount: number, pageIndex: number = 0, queryUrl?: string)
  {
    this.pageSize = pageSize;
    this.totalCount = totalCount;
    this.numPages = Math.ceil(this.totalCount / this.pageSize);

    this._queryUrl = queryUrl;

    this.toIndex(pageIndex);
  }

  toIndex(pageIndex?: number, fireEvent: boolean = false)
  {
    // console.log(`to index ${pageIndex}`);
    
    if(this.numPages === 0) return;
    
    if(pageIndex !== undefined && (pageIndex < 0 || pageIndex >= this.numPages)) throw new Error(`illegal page index: [${pageIndex}]`);
    
    if(pageIndex !== undefined) this.pageIndex = pageIndex;

    let d = parseInt(this._maxSize * .5),
    start = pageIndex - d,
    end = pageIndex + d + 1,
    dStart = 0 - start;

    if(dStart > 0) 
    {
      start += dStart;
      end += dStart;
    }

    let dEnd = end - (this.numPages - 1);

    if(dEnd > 0)
    {
      start -= dEnd;
      end -= dEnd;
    }

    if(start < 0) start = 0;
    // if(end > (this.numPages - 1)) end = this.numPages - 1;

    // console.log(`pageIndex: ${pageIndex}, start: ${start}, end: ${end}`);

    this._start = start;
    this._end = end;

    this.update();

    if(fireEvent)
    {
      this.emiiter.emit("change", this.pageIndex);
    }
  }

  update()
  {    
    let self = this;

    this.$btnPrev.detach();
    this.$btnNext.detach();
    this.$root.empty();

    let currentPageIndex = self.pageIndex;

    if(this._start > 0)
    {
      this.$btnPrev[0].dataset.index = String(this._start - 1);
      if(self._queryUrl) this.$btnPrev.attr("href", self._queryUrl + "&page-index=" + (this._start - 1));
      this.$root.append(this.$btnPrev);
    }

    for(let i=this._start;i<=this._end;i++){createBtn(i);}

    // console.log(`start: ${start}`);

    this.$root.attr("is-single-page", this.numPages <= 1? "true": "false");
    
    

    function createBtn(index: number)
    {
      let btnText = self._useNumber? index + 1: "",
        $btn = $(`<a class="btn">${btnText}</a>`);

      if(self._queryUrl) $btn.attr("href", self._queryUrl + "&page-index=" + index);

      $btn[0].dataset.index = String(index);


      $btn.on("click", (event:Event)=>
      {
        event.preventDefault();

        if(self._isLocking) return;
        if(index === currentPageIndex) return;
        
        if(!self._manualChange) self.toIndex(index);
        self.emiiter.emit("change", index);
      });

      self.$root.append($btn);

      if(index === currentPageIndex)
      {
        $btn.attr("focused", "true");
      }
    }

    if(this._end < (this.numPages - 1))
    {
      this.$btnNext[0].dataset.index = String(this._end + 1);
      if(self._queryUrl) this.$btnNext.attr("href", self._queryUrl + "&page-index=" + (this._end + 1));
      this.$root.append(this.$btnNext);
    }
    
  }
}