import { Vector4 } from "three";
import BufferShader from "../../ts/BufferShader";
// import fragSource from "@shaders/smoothballs.frag";
import fragSource from "@shaders/silk.frag";

export default class IntroScene3Buffer extends BufferShader
{
  constructor(renderer: THREE.WebGLRenderer, size: THREE.Vector2)
  {
    super(renderer, size);
    
    this.build(fragSource, {
    });
  }

  changeMode(mode: "pc" | "mobile")
  {
    // this.uniforms.sphere.value = Sphere[mode];
    this.uniforms.isMobile.value = mode === "mobile";
  }
}