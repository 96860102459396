import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import gsap from "gsap";
import ViewportRoot from "../../ts/sframe/ViewportRoot";
import { Viewport } from "../../ts/sframe/Viewport";
import { ScrollListener } from "../../ts/sframe/ScrollListener";
import Utility from "../../ts/sframe/Utility";
import Tools from "../../ts/Tools";

import { SVG } from "@svgdotjs/svg.js";
import Geom from "../../ts/sframe/Geom";
import GS from "../../ts/GlobalSetting";
import PageManager from "../../PageManager";

const USE_UNIT = "em";

export default class HikaruOoh extends ComponentBase
{

  constructor(elem: ComponentElem)
  {
    super(elem);

    // let $content = this.$root.find(".content");

    this._setupResize();
    this._setupAnimate_test();
    this._setupAnimate();
    this._setupControl();

    // this._testSvg();
  }

  // private _testSvg()
  // {
  //   /*
  //   // initialize SVG.js
  //   var draw = SVG().addTo('#drawing')
  //   // draw pink square
  //   draw.rect(100, 100).move(100, 50).fill('#f06')
  //   */


  //   let t = new window.Vivus('my-svg', { duration: 200 }, () =>
  //   {
  //     console.log("done");
  //   });

  //   t.stop();

  //   t.setFrameProgress(.5);
  // }

  private _setupControl()
  {
    let self = this,
      isLocking = false;

    let $listPart = this.$root.find(".list-part"),
      $groupWrapper = $listPart.find(".group-wrapper"),
      $groups = $groupWrapper.find(".groups"),
      $groupBackground = $listPart.find(".background"),
      $btns = this.$root.find(".buttons>.button");



    const unit = USE_UNIT;

    const rawGroupWidth = {
      "px": 528,
      "em": 26.4,
    },
      speed = {
        "px": 2000,
        "em": 100
      },
      buttonDic: { [name: number]: string } = {
        0: "branding",
        1: "production",
        2: "media",
        3: "social",
        4: "others"
      },

      numGroups = 5,
      groupsWidth = numGroups * rawGroupWidth[unit],
      groupWidth = rawGroupWidth[unit];

    $groupWrapper.append($groups.clone());
    $groupWrapper.append($groups.clone());

    let $controlUi = this.$root.find(".control-ui"),
      $btnPrev = $controlUi.find(".btn.prev"),
      $btnNext = $controlUi.find(".btn.next");

    let currentIndex = 0,
      normalCurrentIndex = 0;

    $btnPrev.on("click", (event: JQuery.ClickEvent) =>
    {
      event.preventDefault();
      toIndex.call(this, currentIndex - 1);
    });

    $btnNext.on("click", (event: JQuery.ClickEvent) =>
    {
      event.preventDefault();
      toIndex.call(this, currentIndex + 1);
    });

    $btns.on("click", function (event: JQuery.ClickEvent)
    {
      event.preventDefault();

      let index = parseInt(this.getAttribute("index"));

      if (index === normalCurrentIndex) return;
      // if(index < normalCurrentIndex) index += numGroups;
      // toIndex.call(self, index);

      let dIndex = index - normalCurrentIndex;
      if (dIndex < 0) dIndex += numGroups;

      // console.log(`button index: ${index}, currentIndex: ${currentIndex}, normalCurrentIndex: ${normalCurrentIndex}`);
      // console.log(`dIndex: ${dIndex}`);

      let targetIndex = currentIndex + dIndex;

      toIndex.call(self, targetIndex);

    });

    let tweenObj = { x: 0 };

    function toIndex(index: number) 
    {
      // if (isLocking) return;
      // isLocking = true;


      let targetX = -index * groupWidth;

      currentIndex = index;



      normalCurrentIndex = index;
      if (normalCurrentIndex < 0) normalCurrentIndex = numGroups - (-normalCurrentIndex % numGroups)
      normalCurrentIndex = normalCurrentIndex % numGroups;


      // console.log(`currentIndex: ${currentIndex}, normalCurrentIndex: ${normalCurrentIndex}`);


      focusButton.call(this, normalCurrentIndex);


      moveTo.call(this, targetX, () =>
      {
        // isLocking = false;
      });
    }

    function focusButton(index: number)
    {
      let buttonName = buttonDic[index] || "";
      // console.log(index);
      // console.log(buttonName);

      $btns.attr("focused", "false");
      if (buttonName) this.$root.find(`.button.${buttonName}`).attr("focused", "true");
    }

    let tl: gsap.core.Timeline;

    function moveTo(x: number, cb: Function)
    {
      //$groupWrapper.css("left")

      let dx = Math.abs(tweenObj.x - x),
        duration = dx / speed[unit];

      duration = Math.max(.4, duration);
      duration = Math.min(.7, duration);

      // console.log(duration);

      Utility.urlParams["ooh-test"] === "2" ?
        tween2.call(this, x, duration, cb) :
        tween1.call(this, x, duration, cb);
    }

    function tween1(x: number, duration: number, cb: Function)
    {
      let direction = (tweenObj.x > x) ? 1 : -1,
        ease1 = "power1.out",
        ease2 = "power1.in";

      if (tl) tl.kill();

      let tweenBackground = (ViewportRoot.index !== 0);

      if (direction === 1)
      {
        let targetWidth = groupWidth - x + tweenObj.x;

        tl = gsap.timeline();
        if(tweenBackground) tl.to($groupBackground, { duration: duration, width: targetWidth + unit, ease: ease1 });

        tl.addLabel("middle");

        tl.to(tweenObj, {
          duration: duration, x: x, onUpdate: () =>
          {
            let newX = (tweenObj.x % groupsWidth) - groupsWidth;
            gsap.set($groupWrapper, { left: newX + unit });

          }, onComplete: () =>
          {
            cb.call(this);
          },
          ease: ease2
        }, "middle");

        if(tweenBackground) tl.to($groupBackground, { duration: duration, width: groupWidth + unit, ease: ease2 }, "middle");

      }
      else
      {
        let targetWidth = groupWidth + x - tweenObj.x;

        tl = gsap.timeline();
        if(tweenBackground) tl.to($groupBackground, { duration: duration, width: targetWidth + unit, ease: ease1 }, 0);

        tl.to(tweenObj, {
          duration: duration, x: x, onUpdate: () =>
          {
            let newX = (tweenObj.x % groupsWidth) - groupsWidth;            
            gsap.set($groupWrapper, { left: newX + unit });
          },
          ease: ease1
        }, 0);

        
        if(tweenBackground) tl.to($groupBackground, {duration: duration, width: groupWidth + unit, ease: ease2});

        tl.add(()=>
        {
          cb.call(this);
        })

      }
    }

    function tween2(x: number, duration: number, cb: Function)
    {
      gsap.to(tweenObj, {
        duration: duration, x: x, onUpdate: () =>
        {
          let newX = (tweenObj.x % groupsWidth) - groupsWidth;

          // console.log(tweenObj.x);
          // console.log(newX);

          gsap.set($groupWrapper, { left: newX + unit });

        }, onComplete: () =>
        {
          cb.call(this);
        }
      });
    }
  }

  private _setupResize()
  {
    if (Utility.urlParams.test === "animation" || Utility.urlParams.test === "layout")
    {
      this.$root.attr("test-for-animation", "true");
      return;
    }

    let autoFull = false;
    if (Utility.urlParams["ooh-test"] === "1") autoFull = true;

    let $outlinePart = this.$root.find(".outline-part"),
      $outlineWrapper = $outlinePart.find(".center-wrapper"),
      $listPart = this.$root.find(".list-part"),
      $marker = this.$root.find("#hikaru-ooh-marker"),
      listPartHeight: number = 0;

    ViewportRoot.emitter.on("updated", (vp) =>
    {
      // this.$root.height(vp.height*6);

      let contentBound = { width: 1900, height: 930 };

      let bound = Geom.caculateContain(vp.width, vp.height, contentBound.width, contentBound.height);

      if(ViewportRoot.index === 0)
      {
        $outlineWrapper.css({
          transform: ""
        });
  
        $listPart.css({
          "font-size": ""
        });

      }
      else
      {

        $outlineWrapper.css({
          transform: `scale(${bound.ratio})`
        });
  
        $listPart.css({
          "font-size": (GS.EM_TO_PX * bound.ratio) + "px"
        });
      }


      if (autoFull) listPartHeight = $listPart.height();
    });

    if (autoFull)
    {
      ScrollListener.addListener("hikaru-ooh-outline-resize", () =>
      {
        let d = $marker[0].getBoundingClientRect().top - listPartHeight;

        d = Math.max(d, -listPartHeight);
        d = Math.min(d, 0);

        $outlinePart.css("min-height", ViewportRoot.height + d);
      });
    }
  }


  private _setupAnimate_test()
  {
    let root = gsap.utils.selector(this.$root[0]),
      bar = root(".blue-bar");

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.$root[0],
        start: "top center",
        end: "bottom 100%",
        scrub: true
      }
    });

    // tl.to(bar, { width: 400 });

    // gsap.to(bar, {
    //   scrollTrigger: {
    //     trigger: this.$root[0],
    //     start: "top center",
    //     end: "bottom 0%",
    //     scrub: true
    //   },
    //   width: 400
    // });

  }

  private _setupAnimate()
  {
    if(Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      start: "top center",
      end: "bottom 100%",
      scrub: 1
    };

    if (Utility.urlParams.test === "animation") trigger = undefined;

    let tl, tl1: any, tl2: any, mainTl: gsap.core.Timeline;

    // 左邊文字入場
    tl = tl1 = gsap.timeline({});

    let chars: { [key: string]: HTMLElement[] } =
    {
      title: Tools.splitText(this.$root.find(".desc-part .title")[0]),
      subtitle: Tools.splitText(this.$root.find(".desc-part .subtitle")[0]),
      detail_pc: Tools.splitText(this.$root.find(".desc-part .detail")[0], true),
      detail_mobile: Tools.splitText(this.$root.find(".desc-part .detail")[1], true),
      whiteDotDesc: Tools.splitText(this.$root.find(".white-dot-desc")[0]),
      g1: []
    };

    chars.g1 = chars.subtitle.concat(chars.detail_pc);
    chars.g2 = chars.subtitle.concat(chars.detail_mobile);

    // console.log(chars.title);
    tl.set(chars.g1, { opacity: 0, y: 50 });
    tl.set(chars.g2, { opacity: 0, y: 50 });
    tl.set(chars.title, { opacity: 0, x: 200 });

    tl.addLabel("start", .01);

    tl.to(chars.title, { duration: .4, opacity: 1, x: 0, stagger: .2, ease: "power3.out" }, "start");
    tl.to(chars.g1, { duration: .4, opacity: 1, y: 0, stagger: .005, ease: "back.out" }, "start+=.1");
    tl.to(chars.g2, { duration: .4, opacity: 1, y: 0, stagger: .005, ease: "back.out" }, "start+=.1");

    // 右邊圖示入場
    tl = tl2 = gsap.timeline({});

    tl.set(q(".white-dot"), { scale: 0 });
    // tl.set(q(".white-dot-desc"), {opacity: 0});

    tl.set(q(".circle"), { opacity: 0 });
    tl.set(q(".rect"), { opacity: 0 });

    tl.set(q(".button"), { opacity: 0 });



    tl.set(chars.whiteDotDesc, { opacity: 0, x: -20, y: 0, scale: 1.5, rotateX: -180 });


    // let frameSvg = new window.Vivus('my-svg', { duration: 200 });
    // frameSvg._progress = 0;
    // frameSvg.stop();
    // frameSvg.setFrameProgress(0);
    // tl.set(frameSvg, { _progress: 0 });
    tl.set(q("#my-svg"), { opacity: 0 });

    tl.addLabel("start", .01);

    // tl.to(frameSvg, {
    //   duration: 1, _progress: 1, onUpdate: () =>
    //   {
    //     frameSvg.setFrameProgress(frameSvg._progress);
    //     frameSvg.stop();
    //   }
    // }, "start");

    tl.to(q("#my-svg"), {duration:.4, opacity: 1}, "start");

    tl.addLabel("button-in", "-=.1");

    tl.to(q(".circle"), { duration: 1, opacity: 1 }, "button-in");
    tl.to(q(".button"), { duration: 1, opacity: 1 }, "button-in");

    tl.to(q(".circle"), { duration: 1.0, left: 0, top: 0, ease: "power2.in" }, "-=.6");

    tl.addLabel("white-dot-in");

    tl.to(q(".white-dot"), { duration: .4, scale: 1, ease: "power4.out" }, "white-dot-in");
    tl.to(chars.whiteDotDesc, { duration: .6, scale: 1, opacity: 1, x: 0, y: 0, rotateX: 0, rotateY: 0, rotateZ: 0, stagger: .01, ease: "power2.out" }, "white-dot-in");

    tl.to(q(".rect"), { duration: .4, opacity: 1, ease: "power3.in" }, "-=.7");

    // tl2.pause();

    // tl2.restart();
    tl2.time(0.01).pause().resume();



    // 混合動畫
    mainTl = gsap.timeline({ scrollTrigger: trigger });
    mainTl.set(q(".graphic-part"), { opacity: 0 });
    mainTl.addLabel("start", .01);
    mainTl.add(tl1, 0);
    mainTl.addLabel("tl2-in", "-=0.5");
    mainTl.set(q(".graphic-part"), { opacity: 1 }, "tl2-in+=.01");
    mainTl.add(tl2, "tl2-in");

    let $buttons = this.$root.find(".buttons");
    mainTl.add(() =>
    {
      $buttons.attr("is-ready", "false");
      this.$root.attr("is-ready", "false");
    });

    mainTl.add(() =>
    {
      $buttons.attr("is-ready", "true");
      this.$root.attr("is-ready", "true");
    }, "+=.01");

    // 讓動畫播畢多一秒的停滯
    mainTl.add(() =>
    {
      // console.log("end");
    }, "+=.5");

    // console.log(mainTl.duration());


    mainTl.seek("start").pause();


    // tl1.time(0, true).tweenTo("start");
    // tl2.time(0, true).tweenTo("start");


    // mainTl.time(0, true);
    // mainTl.tweenTo("my-start");

    // 測試用
    // if(Utility.urlParams.test === "animation") Tools.setTimelineTrigger(this.$root[0], tl1); // 需移除 maiinTl 語法後才有用
    // if(Utility.urlParams.test === "animation") Tools.setTimelineTrigger(this.$root[0], tl2);
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, mainTl, "start"); 

    // PageManager.emitter.on("beforePageOut", (newPage, oldPage)=>
    // {
    //   if(oldPage === "index")
    //   {
    //     console.log("check");
    //     // mainTl.scrollTrigger.disable();
    //     // frameSvg.destroy();
    //   }
    // });

    // PageManager.emitter.on("afterPageIn", (newPage, oldPage)=>
    // {
    //   if(newPage === "index")
    //   {
    //     console.log("new page in");
    //     // mainTl.scrollTrigger.disable();
    //     // frameSvg.reset();
    //   }
    // });

  }
}