import Main from "@root/Main";
import { ComponentDefineDic, ComponentElem } from "@components/interfaces";
import Components from "@components/Compoents";
import Utility from "../../ts/sframe/Utility";
import Popups from "../popups/Popups";
import Intro from "./Intro";
import HikaruOoh from "./HikaruOoh";
import Work from "./Work";
import Pager from "../ui/Pager";
import WorkThumb from "../ui/WorkThumb";
import OurClients from "./OurClients";
import ClientThumb from "./ClientThumb";
import TeamMembers from "./TeamMembers";
import MemberThumb from "./MemberThumb";
import Footer from "../common/Footer";
import CheckboxSmall from "../ui/checkboxs/CheckboxSmall";
import RadioSelect from "../ui/checkboxs/RadioSelect";
import Field from "../ui/inputs/Field";
import Input from "../ui/inputs/Input";
import Textarea from "../ui/inputs/Textarea";
import ContentWrapper from "../ui/ContentWrapper";
import PageManager from "../../PageManager";
import Tools from "../../ts/Tools";
import Follower from "../common/Follower";

let _componentDic: ComponentDefineDic =
{
  "pager": Pager,
  "content-wrapper": ContentWrapper,
  "checkbox-small": CheckboxSmall,
  "radio-select": RadioSelect,
  "field": Field,
  "input": Input,
  "textarea": Textarea,

  "intro": Intro,
  "hikaru-ooh": HikaruOoh,
  "work": Work,
  "work-thumb": WorkThumb,
  "our-clients": OurClients,
  "client-thumb": ClientThumb,
  "team-members": TeamMembers,
  "member-thumb": MemberThumb,
  "footer": Footer,
}

let $root;

Main.preInitPage = ()=>
{
  // ApiProxy.validatePageData("page-data", page_data_schema);
  // ApiProxy.logPageData();

  
}

Main.initPage = () =>
{
  $root = $(`#page-content[page="index"]`);
  PageManager.addPage("index", $root);
}

Main.ready = ()=>
{
  let intro: Intro = Tools.seekComponentByName("intro");
  intro.ready();
  
  if(Utility.urlParams.marker !== "0") new Follower();
}

Components.registComponents(_componentDic);

