import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import { Common } from "@root/components/common/CommonTypes";
import PatternSamples from "@root/ts/PatternSamples";
import { Emitter } from "strict-event-emitter";

export default class Textarea extends ComponentBase implements Common.InputComponent
{
  regexp: RegExp;
  emitter: Emitter<{ change: [value: string, isOk: boolean]; }>;

  _isOk: boolean = false;

  constructor(elem: ComponentElem)
  {
    super(elem);

    this.emitter = new Emitter();

    this.$root.on("input", () =>
    {
      this.update();
    });
  }

  update()
  {
    let input: HTMLInputElement = this.$root[0] as HTMLInputElement;
    if (this.regexp) {
      this._isOk = this.regexp.test(input.value);
      this.emitter.emit("change", input.value, this._isOk);
    }
  }

  changePattern(patternName: string)
  {
    if (PatternSamples[patternName]) {
      this.regexp = PatternSamples[patternName];
    }
    this.update();
  }

  getValue()
  {
    return (<HTMLInputElement>this.$root[0]).value;
  }

  setValue(value: string)
  {
    (<HTMLTextAreaElement>this.$root[0]).value = value;
    this.update();
  }


  isOk()
  {
    return this._isOk;
  }

  reset(): void
  {
    this.$root.val("");
    this.update();
  }

  setMaxLength(num: number)
  {
    (<HTMLInputElement>this.$root[0]).maxLength = num;
  }

  setPlaceholder(placeholder: string)
  {
    (<HTMLInputElement>this.$root[0]).placeholder = placeholder;
  }
}