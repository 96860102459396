import * as THREE from "three";

const VERTEX_SHADER = `
    varying vec2 vUv;
    
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
`;

export default class BufferShader
{
  renderer: THREE.WebGLRenderer;

  readBuffer: THREE.WebGLRenderTarget;
  writeBuffer: THREE.WebGLRenderTarget;

  uniforms: any;
  material: THREE.ShaderMaterial;
  scene: THREE.Scene;

  constructor(renderer: THREE.WebGLRenderer, size: THREE.Vector2) 
  {

    this.renderer = renderer;    

    this.readBuffer = new THREE.WebGLRenderTarget(size.width, size.height, {

      // depthBuffer: false, 
      magFilter: THREE.NearestFilter, // 使用 THREE.NearestFilter 這樣 mobile 裝置才看得到
      minFilter: THREE.NearestFilter, 

      // minFilter: THREE.LinearFilter,
      // magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType,
      stencilBuffer: false
    });

    this.writeBuffer = this.readBuffer.clone();
  }

  build(fragSource: string, uniforms: any = {})
  {
    let commonUniforms: any =
    {
      iTime: {
        type: "f",
        value: 0.0
      },
      iResolution: {
        type: "v2",
        value: new THREE.Vector2(1834, 930)
      },
      iMouse: {
        type: "v3",
        value: new THREE.Vector3(0, 0, 0)
      }
    };
    
    this.uniforms = Object.assign(commonUniforms, uniforms);

    this.material = new THREE.ShaderMaterial({
      fragmentShader: fragSource,
      vertexShader: VERTEX_SHADER,
      uniforms: this.uniforms
    });

    this.scene = new THREE.Scene();
    this.scene.add(
      new THREE.Mesh(new THREE.PlaneGeometry(2, 2), this.material)
    );
  }

  resize(width: number, height: number)
  { 
    // console.log(`width: ${width}, height: ${height}`);
    
    this.readBuffer.setSize(width, height);
    this.writeBuffer.setSize(width, height);
    this.uniforms.iResolution.value.x = width;
    this.uniforms.iResolution.value.y = height;
  }

  swap()
  {
    const temp = this.readBuffer;
    this.readBuffer = this.writeBuffer;
    this.writeBuffer = temp;
  }

  render(camera: THREE.Camera, toScreen = false)
  {

    let scene = this.scene;

    // let size = this.renderer.getSize());
    // this.readBuffer.setSize(size.width, size.height);

    if (toScreen) {
      this.renderer.render(scene, camera);
    } else {
      this.renderer.setRenderTarget(this.writeBuffer);
      this.renderer.clear();
      this.renderer.render(scene, camera)
      this.renderer.setRenderTarget(null);
    }
    this.swap();
  }
}