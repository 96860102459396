import { Common } from "@root/components/common/CommonTypes";
import Tools from "./Tools";
import Field from "@root/components/ui/inputs/Field";
import { ComponentElem } from "@root/components/interfaces";
import EZAlert from "@root/components/popups/EZAlert";
import ApiProxy, { ApiProxyOptions } from "./ApiProxy";
import Input from "@root/components/ui/inputs/Input";
import Textarea from "@root/components/ui/inputs/Textarea";
import CheckboxSmall from "@root/components/ui/checkboxs/CheckboxSmall";



export type SendFormOptions = {
  errorAutoScroll?: boolean
  autoCloseAlert?: boolean
} & ApiProxyOptions;

let FormTools =
{
  resetFields($target: JQuery)
  {
    let list = $target.find(`[component="field"]`).toArray();
    list.forEach((elem:ComponentElem)=>
    {
      let field = elem._component as Field;
      field.reset();
    });
  },

  getFormData($formElem: JQuery, showErrors: boolean = true): Common.FormDataResult
  {
    let isReady: boolean = true,
      formData: any = {},
      firstErrorElem: ComponentElem = null,
      list: ComponentElem[] = [];

    list = list.concat($formElem.find(`[component="field"]:not([collapsed]):not([hidden])`).toArray() as ComponentElem[]);
    list = list.concat($formElem.find(`[component="checkbox-small"]`).toArray() as ComponentElem[]);

    // console.log(list.length);

    list.forEach((elem: ComponentElem) =>
    {
      let field: Common.FormField = elem._component,
        fieldName = field.getDataField(),
        groupName = field.getDataGroup(),
        value = field.getValue();

      // console.log(fieldName);
      // console.log(groupName);
      // console.log(value);

      // if(!fieldName){
      //   console.error(elem);
      //   throw new Error(`表單物件 ${elem} 缺少 data-field 屬性`);
      // } 

      if (fieldName) {
        if (groupName) {
          let obj: any = formData[groupName] ? formData[groupName] : {};
          formData[groupName] = obj;
          obj[fieldName] = value;
        }
        else {
          formData[fieldName] = value;
        }

        if (!field.isOptional()) {
          if (!value) {
            if (showErrors) field.toggleError(true);
            isReady = false;
            if (!firstErrorElem) firstErrorElem = elem;
          }
        }
      }
    });

    return { formData: formData, isReady: isReady, firstErrorElem: firstErrorElem };
  },

  applyFormData($formElem: JQuery, data: any, logIt: boolean = false)
  {
    let list: ComponentElem[] = [];

    list = list.concat($formElem.find(`[component="field"]:not([collapsed]):not([hidden])`).toArray() as ComponentElem[]);
    list = list.concat($formElem.find(`[component="checkbox-small"]`).toArray() as ComponentElem[]);

    // console.log(list.length);

    list.forEach((elem: ComponentElem) =>
    {
      if (elem.dataset.group) {
        let groupName: string = elem.dataset.group,
          groupData: any = data[groupName];

        if (groupData) {
          let fieldName: string = elem.dataset.field;
          let fieldData: any = groupData[fieldName];
          if (fieldData !== undefined) {
            if (elem._component.inputComponent.$root[0]._twzipcode) {
              elem._component.inputComponent.$root[0]._twzipcode.set({ county: groupData["county"], district: groupData["district"] });
            }
            else {
              FormTools.applyFieldData(elem, fieldData);
            }
          }
          else {
            if (elem._component.inputComponent.$root[0]._twzipcode) {
              elem._component.inputComponent.$root[0]._twzipcode.set({ county: "", district: "" });
            }
            else {
              FormTools.applyFieldData(elem, undefined);
            }
            if (logIt) console.log(`field [${groupName}][${fieldName}] is not exist in data`);
          }
        }
        else {
          if (elem._component.inputComponent.$root[0]._twzipcode) {
            elem._component.inputComponent.$root[0]._twzipcode.set({ county: "", district: "" });
          }
          else {
            FormTools.applyFieldData(elem, undefined);
          }

          if (logIt) console.log(`group: ${groupName} is not exist in data`);
        }
      }
      else {

        let fieldName: string = elem.dataset.field;

        let fieldData: any = data[fieldName];

        if (fieldData !== undefined) {
          FormTools.applyFieldData(elem, fieldData);
        }
        else {
          FormTools.applyFieldData(elem, undefined);
          if (logIt) console.log(`field [${fieldName}] is not exist in data`);
        }
      }
    });
  },

  applyFieldData(fieldElem: ComponentElem, data: any)
  {
    // console.log(typeof data);
    let type = fieldElem.getAttribute("type");

    let inputComponent;

    switch (type) {
      case "checkbox":
        data === undefined ?
          (<CheckboxSmall>fieldElem._component).setChecked(false) :
          (<CheckboxSmall>fieldElem._component).setChecked(data);
        break;
      default:
        console.log(`field type: ${type}, data type: ${typeof data}`);

    }

  },

  sendForm(apiName: string, params: any, $errorTarget?: JQuery, options?: SendFormOptions)
  {
    options = Object.assign({ errorAutoScroll: false, autoCloseAlert: true }, options);

    return new Promise((resolve, reject) =>
    {
      EZAlert.pending(true);
      ApiProxy.callApi(apiName, params, options).then((result) =>
      {
        EZAlert.pending(false);
        resolve(result);
      }).catch((error: Common.SendFormError) =>
      {
        EZAlert.pending(false);

        if (error.message) {

          let title: string, text: string;

          if (typeof error.message === "string") {
            title = error.message;
          }
          else {
            title = error.message.title;
            text = error.message.text;
          }

          // let resolveAtStart = error.scroll_to_id? false: true;
          let resolveAtStart = false;

          EZAlert.alert(title, text, options.autoCloseAlert, resolveAtStart).then(() =>
          {
            FormTools.handlerSendFormError(error, $errorTarget, options.errorAutoScroll);
            reject();
          });
        }
        else {
          FormTools.handlerSendFormError(error, $errorTarget, options.errorAutoScroll);
          reject();
        }
      });
    });
  },

  handlerSendFormError(error: Common.SendFormError, $target?: JQuery, autoScroll: boolean = true)
  {
    console.log(error.scroll_to_id);


    let field = FormTools.updateFieldErrors(error.field_errors, $target);

    if (!autoScroll) return;

    if (field) {
      Tools.scrollToElement(field.$root[0], 20);
    }
    else if (error.scroll_to_id) {
      Tools.scrollToElement(error.scroll_to_id);
    }
  },

  updateFieldErrors(fieldErrorList: Common.FieldError[], $target?: JQuery)
  {
    if (!fieldErrorList || fieldErrorList.length === 0) return;

    let firstErrorField: Field;

    if (!$target) $target = $(`#page-content`);

    fieldErrorList.forEach((data) =>
    {
      let query = data.data_group ? `[data-field-query="[${data.data_field}][${data.data_group}]"]` : `[data-field-query="[${data.data_field}]"]`;

      // console.log(query);

      let $elem = $target.find(query);
      if ($elem.length) {
        let field: Field = ($elem[0] as ComponentElem)._component;
        field.toggleError(true, data.error_text);

        if (!firstErrorField) firstErrorField = field;
      }
    });

    return firstErrorField;
  },

  handleNextUrl(result: { next_url?: string })
  {
    if (result.next_url) {
      if (result.next_url === "RELOAD") {
        location.reload();
      }
      else {
        location.href = result.next_url;
      }
    }
  }
};

export default FormTools;