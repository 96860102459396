let PatternSamples: {[key: string]: RegExp} = 
{    
    "email": /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "number": /^\d{1,}$/, // 至少一個數字
    "nonNumber": /[^0-9]/g,
    "only_space": /^\s*$/,
    "passport": /^3\d{7}$/,
    "simple": /.*\S.*/, // 至少含一個非空白字
    "local_call": /^([0-9]{2}[0-9]{4}[0-9]{4})$/,
    // "mobile": /^(09)[0-9]{8}$/,
    "mobile": /^([0-9]{4}[0-9]{3}[0-9]{3})$/,
    "phone": /([0-9]{4}-[0-9]{3}-[0-9]{3})|([0-9]{2}-[0-9]{4}-[0-9]{4})/, // 手機 09xx-xxx-xxx + 市話 0x-xxxx-xxxx
    "person_id": /^[a-zA-Z]{1}[1-2]{1}[0-9]{8}$/,
    "person_id_4": /^[0-9]{4}$/,
    "sports_voucher": /^[0-9]{12}$/,
    "number_and_letters": /^[a-zA-Z0-9]*$/,
    "invoice": /^[a-zA-Z]{2}[0-9]{8}$/,
    "password": /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,12}$/,
    "time": /^([01]\d|2[0-3]):([0-5]\d)$/
};

export default PatternSamples;