import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import { Common } from "@root/components/common/CommonTypes";
import { Emitter } from "strict-event-emitter";

export default class CheckboxSmall extends ComponentBase implements Common.FormField
{
  _isDisabled: boolean = false;
  checked: boolean = false;  
  emitter: Emitter<{"change": [checked: boolean]}>;

  constructor(elem: ComponentElem)
  {
    super(elem);

    this.emitter = new Emitter();

    this.checked = this.$root[0].getAttribute("checked") === "true";

    let $trigger = this.$root.attr("hitarea") === "self" ? this.$root : this.$root.find(".icon");    

    $trigger.on("click", (event: Event) =>
    {            
      event.preventDefault();
      if(this._isDisabled) return;
      this.setChecked(!this.checked);
    });
  }

  update()
  {
    this.$root[0].setAttribute("checked", this.checked? "true": "false");
    this.emitter.emit("change", this.checked);
    
  }

  setChecked(b: boolean, updateNow: boolean = true)
  {
    this.checked = b;
    this.$root[0].setAttribute("checked", this.checked? "true": "false");
    if(updateNow) this.update();
  }

  toggleDisabled(b: boolean)
  {
    this._isDisabled = b;
    this._isDisabled? this.$root[0].setAttribute("disabled", "true"): this.$root[0].removeAttribute("disabled");
  }

  isOptional()
  {
    return !(this.$root.attr("is-optional") === "false");
  }

  getValue(){    
    return this.checked;
  }

  getDataValue(){    
    return this.$root[0].dataset.value;
  }

  getDataText(){    
    return this.$root[0].dataset.text;
  }

  getDataField()
  {
    return this.$root[0].dataset.field;
  }

  getDataGroup()
  {
    return this.$root[0].dataset.group;
  }

  getChecked()
  {
    return this.checked;
  }
}