import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Tools from "../../ts/Tools";
import Components from "../Compoents";
import RwdPicture from "../RwdPicture";
import { ClientThumbData } from "./OurClients";

export default class ClientThumb extends ComponentBase
{
  static generate(data: ClientThumbData)
  {
    return new ClientThumb(ClientThumb.createElem(), data);
  }

  constructor(elem: ComponentElem, data?: ClientThumbData)
  {
    super(elem);
    
    if(data)
    {
      Components.bindScripts(elem);
      
      let rp = Tools.seekComponentByClass("image", this.$root) as RwdPicture;
      rp.setDataSrc(data.pc, "pc");
      rp.setDataSrc(data.mobile, "mobile");
      rp.load();
    }
  }
}