import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import CheckboxSmall from "./CheckboxSmall";
import { Emitter } from "strict-event-emitter";
import { Common } from "@root/components/common/CommonTypes";


export default class RadioSelect extends ComponentBase implements Common.FormField
{
  static relyOthers = true;
  
  isSingleSelect: boolean = false;
  checkboxList: CheckboxSmall[];
  emitter: Emitter<{"change": []}>;

  _value: string | string[];
  _text: string | string[];

  constructor(elem: ComponentElem)
  {
    super(elem);

    this.emitter = new Emitter();
    this.isSingleSelect = this.$root.attr("is-single-select") === "true";

    this.resetOptions();
  }

  resetOptions()
  {
    
    this.checkboxList = [];

    let checkboxElemList = this.$root.find(`[component="checkbox-small"]`).toArray();
    
    checkboxElemList.forEach((elem: ComponentElem, index)=>
    {
      let checkbox: CheckboxSmall = elem._component,
      value = elem.dataset.value;      

      if(value === undefined) 
      {
        console.log(elem);
        throw new Error(`checkbox 缺少 data-value 屬性`);
      }

      this.checkboxList.push(checkbox);
      
      checkbox.emitter.on("change", ()=>
      {
        this.isSingleSelect? this._singleSelectOn(index): this.update();
      });
    }); 

  }

  reset()
  {
    this.checkboxList.forEach((checkbox)=>
    {
      checkbox.setChecked(false);
    });
  }

  update()
  {
    let array: string[] = [],
    textArray: string[] = [],
    lastSelectValue,
    lastSelectText;

    this.checkboxList.forEach((checkbox, index)=>
    {
      if(checkbox.getChecked())
      {
        array.push(checkbox.getDataValue());
        lastSelectValue = checkbox.getDataValue();

        textArray.push(checkbox.getDataText());
        lastSelectText = checkbox.getDataText();
      }
    });

    this._value = this.isSingleSelect? lastSelectValue: array;
    this._text = this.isSingleSelect? lastSelectText: textArray;

    this.emitter.emit("change");
  }

  isOptional()
  {
    return !(this.$root.attr("is-optional") === "false");
  }

  getValue(): string | string[]
  {
    return this._value;
  }

  setValue(value: string | string[])
  {
    if(typeof value === "string")
    {
      this.checkboxList.forEach((checkbox)=>
      {
        checkbox.setChecked(checkbox.$root[0].dataset.value === value, false);
      });
    }
    else
    {
      let dic: any = {};

      value.forEach((key)=>
      {
        dic[key] = true;
      });

      this.checkboxList.forEach((checkbox)=>
      {
        checkbox.setChecked(Boolean(dic[checkbox.$root[0].dataset.value]), false);
      });

    }
  }

  getText(): string | string[]
  {
    return this._text;
  }

  getDataField()
  {
    return this.$root[0].dataset.field;
  }

  getDataGroup()
  {
    return this.$root[0].dataset.group;
  }

  _singleSelectOn(selectIndex: number)
  {
    this.checkboxList.forEach((checkbox, index)=>
    {
      checkbox.setChecked(selectIndex === index, false);
    });
    this.update();
  }


}