import * as THREE from "three";
import gsap from "gsap";

const VERTEX_SHADER = `
  varying vec2 vUv;
  
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
  }
`;

export default class ShaderCanvas
{
  canvas: HTMLCanvasElement;  
  uniforms: any = {};

  isInViewport: boolean = true;

  protected _camera: THREE.Camera;
  protected _clock: THREE.Clock;
  protected _scene: THREE.Scene;
  protected _renderer: THREE.WebGLRenderer;

  

  protected _imouse: THREE.Vector3;

  protected _width: number;
  protected _height: number;

  protected _isStarted: boolean = false;

  protected _isPaused: boolean = false;

  constructor()
  {    

    this._clock = new THREE.Clock();   

    this._camera = new THREE.Camera();
    this._camera.position.z = 1;
    this._scene = new THREE.Scene();    
    
    this._renderer = new THREE.WebGLRenderer({alpha: true});
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._renderer.domElement.id = "main-canvas";

    this.canvas = this._renderer.domElement;

    
    // self.build();
  }

  start()
  { 
    if(this._isStarted) return;
    this._isStarted = true;

    let self = this;
    function animate()
    {
      requestAnimationFrame(animate);
      self.render();
    }
    requestAnimationFrame(animate);
  }

  build(fragSource: string, uniforms: any = {}, vertSource?: string)
  {
    this._imouse = new THREE.Vector3(0, 0, 0);

    /*
    window.addEventListener("mousemove",  (evt)=>
    {
      this._imouse.x = evt.clientX;
      this._imouse.y = window.innerHeight - evt.clientY;

      // console.log(this._imouse);
    });

    window.addEventListener("mousedown", () =>
    {
      this._imouse.z = 1;
      //console.log(imouse);
    });

    window.addEventListener("mouseup", () =>
    {
      this._imouse.z = 0;
      //console.log(imouse);

    });
    */

    let commonUniforms = 
    {
      // resolution: {
      //   value: new THREE.Vector2(window.innerWidth, window.innerHeight)
      // },
      iTime: {
        type: "f",
        value: 0.0
      },
      iResolution: {
        type: "v2",
        value: new THREE.Vector2()
      },
      iMouse: {
        type: "v3",
        value: this._imouse
      }
    };

    this.uniforms = Object.assign(commonUniforms, uniforms);

    if(!vertSource) vertSource = VERTEX_SHADER;

    let geomtry = new THREE.PlaneGeometry(2, 2);
    let material = new THREE.ShaderMaterial
      ({
        uniforms: this.uniforms,
        vertexShader: vertSource,
        fragmentShader: fragSource,

        // blending: THREE.NormalBlending,
        // depthTest: true,
        // transparent: true,
        // clipping: true
      });


    let mesh = new THREE.Mesh(geomtry, material);
    this._scene.add(mesh);

    
  }

  protected bindMouseEvent(useTween: boolean = true, tweenDuration: number = .5)
  {
    let self = this,main,
      $win = $(window),
      $canvas = $(this.canvas),
      isPressing = false;

    $canvas.on("mousedown", (evt)=>
    {
      // console.log("touch start");
      isPressing = true;
      this.uniforms.iMouse.value.z = 1;
      updateMousePosition(evt.clientX, evt.clientY);
      $win.on("touchend mouseup", onMouseUp);
      
    });

    $canvas.on("mousemove", (evt)=>
    {
      updateMousePosition(evt.clientX, evt.clientY);
    }); 

    function onMouseUp(evt: any)
    {
      isPressing = false;
      updateMousePosition(evt.clientX, evt.clientY);

      // self.uniforms.iMouse.value.z = useTween? 1: 0;
      self.uniforms.iMouse.value.z = 0;

      // console.log("up");
      
      
      $win.unbind("touchend mouseup", onMouseUp);
    }

    function updateMousePosition(clientX: number, clientY: number)
    {
      let bound = self.canvas.getBoundingClientRect();

      let v1 = new THREE.Vector2(clientX - bound.x, clientY - bound.y);      

      if(useTween)
      {
        gsap.killTweensOf(self.uniforms.iMouse.value);        
        gsap.to(self.uniforms.iMouse.value, {duration: tweenDuration, x: (clientX - bound.x), y: (self._height - (clientY - bound.y))});
      }
      else
      {
        self.uniforms.iMouse.value.x = clientX - bound.x;
        self.uniforms.iMouse.value.y = self._height - (clientY - bound.y);

      }

      // console.log(`mouse position: ${v1.x}, ${v1.y}`);
    }
  }

  autoResize()
  {
    let self = this;

    window.addEventListener('resize', onWindowResize, false);
    function onWindowResize()
    {
      self._renderer.setSize(self._width, self._height);
      self.uniforms.iResolution.value.x = self._renderer.domElement.width;
      self.uniforms.iResolution.value.y = self._renderer.domElement.height;
    }

    onWindowResize();
  }

  resize(width: number, height: number)
  {
    this._width = width;
    this._height = height;

    this._renderer.setSize(this._width, this._height);
    this.uniforms.iResolution.value.x = width;
    this.uniforms.iResolution.value.y = height;

    this.canvas.setAttribute("width", width.toString());
    this.canvas.setAttribute("height", height.toString());
  }

  render()
  {
    if(!this.isInViewport) return;    

    if(this._isPaused) return;

    this.uniforms.iTime.value = this._clock.getElapsedTime();

    // bufferShader.render(this._camera);
    // this.uniforms.tTriangle.value = bufferShader.readBuffer.texture;

    this._renderer.render(this._scene, this._camera);
  }

  // loadResources()
  // {
  //   let res: any = {};

  //   return new Promise((resolve) =>
  //   {
  //     loadData(fragUrl).then((data) =>
  //     {
  //       res.frag = data;
  //       return loadData(vertUrl);
  //     }).then((data) =>
  //     {
  //       res.vert = data;
  //       resolve(res);
  //     });

  //   });
  // }
}

// function loadData(url: string)
// {
//   return new Promise((resolve, reject) =>
//   {
//     var xhr = new XMLHttpRequest();
//     xhr.responseType = 'text';
//     xhr.addEventListener("load", () => { resolve(xhr.responseText); });
//     xhr.open("post", url);
//     xhr.send();
//   });
// }