import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Utility from "../../ts/sframe/Utility";
import Tools from "../../ts/Tools";
import gsap from "gsap";
import ApiProxy from "../../ts/ApiProxy";
import Pager from "../ui/Pager";
import ClientThumb from "./ClientThumb";
import ViewportRoot from "../../ts/sframe/ViewportRoot";
import Geom from "../../ts/sframe/Geom";
import GS from "../../ts/GlobalSetting";
import { ScrollTrigger } from "gsap/all";

export type ClientThumbData = {
  pc: string,
  mobile: string
}

const SETTING =
{
  pc: {
    maxColSize: 6,
    minColSize: 2,
    maxRowSize: 2,
    minRowSize: 2,
    containerWidth: 1675,
    bleed: 1834 - 1675
  },
  mobile: {
    maxColSize: 2,
    minColSize: 2,
    maxRowSize: 3,
    minRowSize: 1,
    containerWidth: 640,
    bleed: 0
  }
}

export default class OurClients extends ComponentBase
{
  private _dataList: ClientThumbData[];
  private _pager: Pager;
  private _thumbList: ClientThumb[] = [];

  private _colSize: number = 0;
  private _rowSize: number = 2;

  private _updatePageSize: Function;


  targetX: number = 0;
  targetDirection: number = 0;

  constructor(elem: ComponentElem)
  {
    super(elem);

    this._setupResize();
    this._setupContents();
    this._setupAnimate();
    this._setupDrag();

    setTimeout(() =>
    {
      ScrollTrigger.refresh();
    }, 100);
  }

  private _setupResize()
  {
    if (Utility.urlParams.test === "animation" || Utility.urlParams.test === "layout")
    {
      this.$root.attr("test-for-animation", "true");
    }

    // if(Utility.urlParams.test === "animation") return;



    let $marker = this.$root.find("#our-clients-marker"),
      $content = this.$root.find(">.content");

    ViewportRoot.emitter.on("updated", (vp) =>
    {
      // $marker.css("bottom", Math.max(1200, vp.height));

      let bound = Geom.caculateContain(vp.width - GS.getNavSize().width, vp.height - GS.getNavSize().height, 1836, 930);
      // console.log(bound.ratio);
      let ratio = (vp.width - GS.getNavSize().width) / (1836);
      ratio = Math.min(1, ratio);

      if (ViewportRoot.index === 0)
      {
        this.$root.css({
          "font-size": ""
        });
      }
      else
      {
        this.$root.css({
          "font-size": (GS.EM_TO_PX * ratio) + "px"
        });
      }

      let p = ViewportRoot.index === 0 ? SETTING.mobile : SETTING.pc;

      const thumbWidth = getThumbWidth(),
        gapX = getGapX(),
        singleSize = thumbWidth + gapX,
        maxColSize = p.maxColSize,
        minColSize = p.minColSize,
        bleed = 100;

      let newColSize = parseInt(((vp.width - GS.getNavSize().width) - bleed) / singleSize);

      newColSize = Math.min(maxColSize, newColSize);
      newColSize = Math.max(minColSize, newColSize);



      // console.log(newColSize);

      this._updatePageSize(newColSize, p.maxRowSize);
      // this._updatePageSize(6);
    });
  }

  private _setupAnimate()
  {
    if (Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root.find(".top-part")[0],
      start: "top bottom",
      end: "bottom top",
      markers: false,
      scrub: 1
    };

    let trigger2 = {
      trigger: this.$root[0],
      start: "top center",
      end: "bottom 100%",
      scrub: 1
    };

    if (Utility.urlParams.test === "animation") trigger = undefined;
    if (Utility.urlParams.test === "animation") trigger2 = undefined;

    let tl1, tl2;

    tl1 = gsap.timeline({ scrollTrigger: trigger });
    tl2 = gsap.timeline({ scrollTrigger: trigger2 });

    tl1.set(q(".main-title"), { left: "100%" });
    tl2.set(q(".thumb-container>.wrapper"), { rotateX: -60, y: 500, opacity: 0 });
    tl2.set(q(".pager"), { opacity: 0 });
    // tl.set(q(".gap-line"), {height: 0});

    tl1.addLabel("start", .01);
    tl2.addLabel("start", .01);

    tl1.to(q(".main-title"), { duration: 1, left: "0", ease: "none" }, "start");

    tl2.addLabel("thumb-in", ".3");
    tl2.to(q(".thumb-container>.wrapper"), { duration: .3, opacity: 1 }, "thumb-in");
    tl2.to(q(".thumb-container>.wrapper"), { duration: 1, rotateX: 0, y: 0 }, "thumb-in");
    tl2.to(q(".pager"), { duration: .3, opacity: 1 }, "-=.3");

    tl2.add(() =>
    {
    }, "+=.5");


    // 混合動畫
    // mainTl = gsap.timeline({ scrollTrigger: trigger });
    // // mainTl.set(q(".graphic-part"), { opacity: 0 });
    // mainTl.addLabel("start", .01);
    // mainTl.add(tl1, 0);
    // // mainTl.addLabel("tl2-in", "-=0.5");

    // mainTl.add(() =>
    // {
    //   // console.log("end");
    // }, "+=.5");

    tl2.seek("start").pause();

    // // 測試用
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, tl1, "start");
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, tl2, "start");
  }

  private _setupDrag()
  {
    let self = this,
      isDragging = false;

    let startX: number,
      targetX: number,
      $wrapper = self.$root.find(".thumb-container");

    // let ham = self.hamDrag = new Hammer($wrapper[0], {});
    let ham = new Hammer($wrapper[0], {});

    $wrapper.find(">*").on("dragstart", () =>
    {
      return false;
    });

    ham.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });

    ham.on("panstart", (event: any) =>
    {
      if(self._pager.numPages <= 1) return;

      isDragging = true;
      startX = parseInt($wrapper.css("margin-left"));
      gsap.killTweensOf($wrapper[0]);
      // gsap.to($wrapper[0], { marginLeft: 0, duration: .1 });

      $wrapper.find(">*").css("pointer-events", "none");
    });

    ham.on("pan", (event: any) =>
    {
      if (!isDragging) return;

      if (Math.abs(event.deltaY) > 200) return;

      targetX = startX + event.deltaX

      targetX = targetX;

      gsap.killTweensOf($wrapper[0]);
      gsap.to($wrapper[0], { marginLeft: targetX, duration: .35, ease: `power1.out` });

    });

    ham.on("panend", (event: any) =>
    {
      setTimeout(() =>
      {
        isDragging = false;
        $wrapper.find(">*").css("pointer-events", "");
      }, 300);

      self.preRebound(targetX);

      gsap.killTweensOf($wrapper[0]);
      gsap.to($wrapper[0], { marginLeft: 0, duration: .2, ease: `power1.out` });
    });
  }


  preRebound(dx: number)
  {
    if (dx > ViewportRoot.width * .3)
    {
      this._pager.toPrevPage(); 
    }
    else if (dx < -ViewportRoot.width * .3)
    {
      this._pager.toNextPage();
    }
  }

  private _setupContents()
  {
    this._dataList = ApiProxy.getStoredData("index/get-client-list").list;

    let self = this,
      isLocking = false,
      currentIndex = -1,
      pageSize = 6,
      numItems = this._dataList.length,
      cbUnlocking: Function = undefined;

    this._pager = Tools.seekComponentByName("pager", this.$root);
    this._pager.setUseNumber(false).setMaxSize(100000).setManualChange(true);
    this._pager.reset(pageSize, numItems);
    this._pager.emiiter.on("change", (pageIndex: number) =>
    {
      // console.log(`new page index: ${pageIndex}`);
      if (isLocking) return;
      toPage(pageIndex);
    });

    let $wrapper = this.$root.find(".thumb-container>.wrapper").empty(),
      $thumbContainer = this.$root.find(".thumb-container");

    // toPage(0, true);

    self._updatePageSize = (newColSize: number, newRowSize: number) =>
    {
      // console.log(`newColSize: ${newColSize}, newRowSize: ${newRowSize}`);

      if (newColSize === self._colSize && newRowSize === self._rowSize) return;

      (isLocking) ? cbUnlocking = execute : execute();


      function execute()
      {
        self._colSize = newColSize;
        self._rowSize = newRowSize;
        pageSize = self._colSize * self._rowSize;

        $thumbContainer.css("width", (self._colSize * (getThumbWidth() + getGapX())) + getGapX());

        self._pager.reset(pageSize, numItems);
        toPage(0, true);
      }
    };

    function toPage(index: number, instantPlay: boolean = false)
    {
      if (isLocking) return; isLocking = true;

      currentIndex = index;
      self._pager.toIndex(currentIndex);
      update(instantPlay);
    }

    let colSize: number,
      singleSize: number,
      totalWidth: number;

    function update(instantPlay: boolean = false) 
    {
      colSize = self._colSize,
        singleSize = getThumbWidth() + getGapX(),
        totalWidth = (singleSize * colSize) * .5;

      contentOut(() =>
      {
        $wrapper.empty();
        self._thumbList = [];

        let startIndex = currentIndex * pageSize,
          endIndex = Math.min(startIndex + pageSize, numItems);

        // console.log(`startIndex: ${startIndex}, endIndex: ${endIndex}`);
        let colSize = self._colSize;

        for (let i = startIndex; i < endIndex; i++)
        {
          let thumb = ClientThumb.generate(self._dataList[i]),
            $img = thumb.$root.find("picture");

          thumb.$root.attr("pc-col-size", self._colSize);

          // gsap.set(thumb.$root, { opacity: 0, rotateX: 45, rotateZ: -15, x: -200, y: -100 });
          $wrapper.append(thumb.$root);
          self._thumbList.push(thumb);

          let index = self._thumbList.length,
            elem = thumb.$root[0],
            ///@ts-ignore
            obj: any = elem._p = {};

          obj.direction = parseInt(i / colSize) % 2 === 0 ? 1 : -1,
            obj.t = obj.direction === 1 ? i % colSize : colSize - 1 - i % colSize;


          gsap.set(elem, {
            opacity: 0,
            x: obj.t * -singleSize * obj.direction,
            y: obj.direction * 111,
            rotateY: -90 * obj.direction
          });

          // thumb.$root.css("perspective-origin", obj.direction === 1? "right center": "left center");
        }

        contentIn(() =>
        {
          isLocking = false;
          if (cbUnlocking)
          {
            let func = cbUnlocking;
            cbUnlocking = undefined;
            func.call(null);
          }
        }, instantPlay);

      }, instantPlay);


    }

    function contentOut(cb: Function, instantPlay: boolean = false)
    {
      let duration = instantPlay ? 0 : .5,
        stagger = instantPlay ? 0 : .05;

      let tl = gsap.timeline(),
        $thumbs = self.$root.find(`[component="client-thumb"]`);


      for (let i = 0; i < self._thumbList.length; i++)
      {
        let elem = self._thumbList[i].$root[0],
          $img = self._thumbList[i].$root.find("picture"),
          ///@ts-ignore
          obj = elem._p;

        // tl.to(elem, {
        //   duration: duration,
        //   ease: "power1.out",
        //   x: -100 * (obj.t-2) * obj.direction,
        //   rotateY: - 180 * obj.direction,
        //   opacity: 0,
        // }, instantPlay? 0: (colSize - obj.t) * .1);

        // tl.to($img, {
        //   duration: .1,
        //   ease: "power1.out",
        //   opacity: 0
        // }, instantPlay? 0: duration*.5 + (colSize - obj.t) * .1);

        tl.to(elem, {
          duration: duration,
          ease: "power1.in",
          x: obj.t * -singleSize * obj.direction + (totalWidth * obj.direction),
          y: obj.direction * 111,
          rotateY: -90 * obj.direction
        }, instantPlay ? 0 : (colSize - obj.t) * .05);

        tl.to(elem, {
          duration: duration * .2,
          opacity: 0,
        }, instantPlay ? 0 : (colSize - obj.t) * .05 + duration * .8);

        tl.to($img, {
          duration: duration * .1,
          ease: "power1.out",
          opacity: 0
        }, instantPlay ? 0 : (colSize - obj.t) * .05);

      }

      // tl.to($thumbs, { duration: duration, opacity: 0, stagger: stagger, rotateY: 70, rotateZ: 15, x: 200, y: -100, ease: "power1.in" });

      tl.add(() =>
      {
        cb.call(null);
      });
    }

    function contentIn(cb: Function, instantPlay: boolean = false) 
    {
      let duration = instantPlay ? 0 : .5,
        stagger = instantPlay ? 0 : .05;

      let tl = gsap.timeline(),
        $thumbs = self.$root.find(`[component="client-thumb"]`);

      let colSize = self._colSize;

      for (let i = 0; i < self._thumbList.length; i++)
      {
        let elem = self._thumbList[i].$root[0],
          $img = self._thumbList[i].$root.find("picture"),
          ///@ts-ignore
          obj = elem._p;

        tl.to(elem, {
          duration: duration,
          ease: "power1.out",
          x: 0,
          y: 0,
          rotateY: 0
        }, instantPlay ? 0 : (colSize - obj.t) * .05);

        tl.to(elem, {
          duration: duration * .2,
          opacity: 1
        }, instantPlay ? 0 : (colSize - obj.t) * .05);
      }

      // tl.to($thumbs, { duration: duration, opacity: 1, rotateX: 0, rotateZ: 0, stagger: stagger, x: 0, y: 0 });

      tl.add(() =>
      {
        cb.call(null);
      });
    }
  }
}

function getThumbWidth()
{
  return ViewportRoot.index === 0 ? 291 : 222;
}

function getThumbHeight()
{
  return ViewportRoot.index === 0 ? 292 : 222;
}

function getGapX()
{
  return 1;
}