import gsap from "gsap";
import ViewportRoot from "../../ts/sframe/ViewportRoot";

export default class Follower
{
  constructor()
  {
    if(ViewportRoot.index === 0) return;

    let $elem = $(`<div id="follower"><div class="circle"></div></div>`);
    $("body").append($elem);

    let firstTime = true;

    $(window).on("mousemove", (evt)=>
    {
      // updateMousePosition(evt.clientX, evt.clientY);
      // $elem.css("left", evt.clientX);
      // $elem.css("top", evt.clientY);

      if(firstTime)
      {
        firstTime = false;
        
        gsap.set($elem, {left: evt.clientX, top: evt.clientY});
        gsap.from($elem, {duration: .3, opacity: 0});
      }
      else
      {
        gsap.to($elem, {duration: .3, left: evt.clientX, top: evt.clientY});
      }


      // console.log(evt.target);

      let isActive = "false";

      // console.log(evt.target.st);
      if(evt.target && evt.target instanceof HTMLElement)
      {
        let t = evt.target as HTMLElement;

        if($(t).css("cursor") === "pointer")
        {
          isActive = "true";
        }
      }
      
      $elem.attr("active", isActive);
      
      
    }); 
  }
}