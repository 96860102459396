import * as THREE from "three";
import BufferShader from "../../ts/BufferShader";

// import fragSource from "@shaders/beam-light-2.frag";
import fragSource from "@shaders/intro.frag";
import vertSource from "@shaders/common.vert";
import ShaderCanvas from "@ts/ShaderCanvas";

import gsap from "gsap";
import { Viewport } from "../../ts/sframe/Viewport";
import IntroScene2Buffer from "./IntroScene2Buffer";
import IntroScene3Buffer from "./IntroScene3Buffer";

const tTriangleResolution = new THREE.Vector2(1325, 695);

type ModeType = 0 | 1 | 2 | 3;
const MAX_MODE_INDEX = 3;

export default class IntroCanvas extends ShaderCanvas
{
  bufferShader2: IntroScene2Buffer;
  bufferShader3: IntroScene3Buffer;

  private _isModeLocking: boolean = false;
  private _currentMode: ModeType = 0;

  private _triangleTextures: THREE.Texture[] = [];

  constructor(autoStart: boolean = true, autoResize: boolean = true)
  {    
    super();
    
    this.canvas.id = "intro-canvas";

    // THREE.ColorManagement.enabled = true;

    // 需改變紋理重複顯示同 shadertoy 一致
    let tNoise = new THREE.TextureLoader().load("static/images/intro/noise.png");
    tNoise.wrapS = THREE.RepeatWrapping;
    tNoise.wrapT = THREE.RepeatWrapping;

    let tTriangle0 = this._triangleTextures[0] = this._triangleTextures[1] = new THREE.TextureLoader().load("static/images/intro/intro-triangle-gray.png");
    tTriangle0.wrapS = THREE.RepeatWrapping;
    tTriangle0.wrapT = THREE.RepeatWrapping;

    // let tTriangle1 = this._triangleTextures[1] = new THREE.TextureLoader().load("static/images/intro/intro-triangle-1.png");
    // tTriangle1.wrapS = THREE.RepeatWrapping;
    // tTriangle1.wrapT = THREE.RepeatWrapping;

    let tTriangle2 = this._triangleTextures[2] = new THREE.TextureLoader().load("static/images/intro/intro-triangle-2.png");
    tTriangle2.wrapS = THREE.RepeatWrapping;
    tTriangle2.wrapT = THREE.RepeatWrapping;

    let tTriangle3 = this._triangleTextures[3] = new THREE.TextureLoader().load("static/images/intro/intro-triangle-3.png");
    tTriangle3.wrapS = THREE.RepeatWrapping;
    tTriangle3.wrapT = THREE.RepeatWrapping;


    let uniforms: any = 
    {
      uScale:{
        type: "f",
        value: 1
      },

      uBeamProgress:{
        type: "f",
        value: 0
      },

      uFocusPoint:{
        type: "v2",
        value: new THREE.Vector2(0, 0)
      },

      uLightPointTo:{
        type: "v2",
        value: new THREE.Vector2(0, 0)
      },
      tNoise:
      {
        value: tNoise
      },
      uMode:{
        type: "int",
        value: 0
      },
      uModeProgress:
      {
        value: 1
      },
      uBuffer2Progress:
      {
        value: 0
      },
      uBuffer3Progress:
      {
        value: 0
      },
      tTriangle0:
      {
        value: tTriangle0
      },
      tTriangle1:
      {
        value: this._triangleTextures[0]
      },
      tTriangleResolution:
      {
        value: tTriangleResolution
      },
      tTriangleOffset:
      {
        type: "v2",
        value: new THREE.Vector2(0, 0)
      },
      iChannel2:
      {
        value: null
      },
      iChannel3:
      {
        value: null
      }
    };

    this.build(fragSource, uniforms, vertSource);

    let $intro = $("#intro"),
      width = $intro.width(),
      height = $intro.height();
    
    this.bufferShader2 = new IntroScene2Buffer(this._renderer, new THREE.Vector2(width, height));
    this.bufferShader2.uniforms.iMouse = this.uniforms.iMouse;
    this.bufferShader2.uniforms.iTime = this.uniforms.iTime;
    
    
    this.bufferShader3 = new IntroScene3Buffer(this._renderer, new THREE.Vector2(width, height));
    this.bufferShader3.uniforms.iMouse = this.uniforms.iMouse;
    this.bufferShader3.uniforms.iTime = this.uniforms.iTime;

    // this._bindMouseEvent();
    this.bindMouseEvent();

    
    if(autoResize) this.autoResize();
    if(autoStart) this.start();
  }

  toFirstMode()
  {
    this.toMode(2);
  }

  toNextMode()
  {
    let nextModeIndex = this._currentMode + 1;
    if(nextModeIndex > MAX_MODE_INDEX) nextModeIndex = 1;
    this.toMode(nextModeIndex as ModeType);
  }

  toMode(mode: ModeType)
  {
    if(this._currentMode === mode) return;
    if(this._isModeLocking) return;
    this._isModeLocking = true;
    
    let oldMode = this._currentMode;
    this._currentMode = mode;

    this.uniforms.uMode.value = this._currentMode;

    // console.log(`change mode to: ${this._currentMode}`);

    if(oldMode === 0)
    {
      gsap.to(this.uniforms.uBeamProgress, {duration: .6, value: 1, ease: "power3.in"});
    }

    this.uniforms.tTriangle0.value = this._triangleTextures[oldMode];
    this.uniforms.tTriangle1.value = this._triangleTextures[this._currentMode];

    let tl = gsap.timeline();
    tl.from(this.uniforms.uModeProgress, {duration: .6, value: 0, ease: "power3.in"});

    if(mode === 2)
    {
      tl.to(this.uniforms.uBuffer2Progress, {duration: .6, value: 1, ease: "power3.in"}, 0);
    }
    else if(oldMode === 2)
    {
      tl.to(this.uniforms.uBuffer2Progress, {duration: .6, value: 0, ease: "power3.in"}, 0);
    }

    if(mode === 3)
    {
      tl.to(this.uniforms.uBuffer3Progress, {duration: .6, value: 1, ease: "power3.in"}, 0);
    }
    else if(oldMode === 3)
    {
      tl.to(this.uniforms.uBuffer3Progress, {duration: .6, value: 0, ease: "power3.in"}, 0);
    }

    tl.add(()=>
    {
      this._isModeLocking = false;
    });
  }

  // toggleLightOn(b: boolean)
  // {
  //   this.uniforms.uLightOn.value = b;
  // }

  resize(width: number, height: number, scale: number = 1, viewportIndex: number = 1)
  {
    this.uniforms.uScale.value = scale;

    const tTriangleTop = new THREE.Vector2(686, 695); // 三角形貼圖頂點的座標
    const tTriangleTopOffset = viewportIndex === 0? new THREE.Vector2(0, 67): new THREE.Vector2(312, 227); // 三角形頂點置於 (0, 0) 後, 需添加的位移量
    this.uniforms.tTriangleOffset.value = new THREE.Vector2(-tTriangleTop.x + tTriangleTopOffset.x, -tTriangleTop.y + tTriangleTopOffset.y);

    this.uniforms.uFocusPoint.value = viewportIndex === 0? new THREE.Vector2(0 * scale, -545 * scale): new THREE.Vector2(312, -70); // 聚焦點座標
    
    let rawPoint;

    if(viewportIndex === 0)
    {
      let triangleShrink = .8;
      this.uniforms.uLightPointTo.value = new THREE.Vector2(width*.5 + 806*scale, height*.5 + 481*scale);
      this.uniforms.tTriangleOffset.value.x *= triangleShrink;
      this.uniforms.tTriangleOffset.value.y *= triangleShrink;
      this.uniforms.tTriangleResolution.value = new THREE.Vector2(tTriangleResolution.x * triangleShrink, tTriangleResolution.y * triangleShrink);
    }
    else
    {
      this.uniforms.uLightPointTo.value = new THREE.Vector2(width*.5 + 806*scale, height*.5 + 321*scale);
      this.uniforms.tTriangleResolution.value = tTriangleResolution;
    }
    
    this.bufferShader2.resize(width, height);
    this.bufferShader3.resize(width, height);
    super.resize(width, height);
  }

  render()
  {
    if(!this.isInViewport) return;

    if(this.uniforms.uBuffer2Progress.value !== 0)
    {
      this.bufferShader2.render(this._camera);
      this.uniforms.iChannel2.value = this.bufferShader2.readBuffer.texture;
    }

    if(this.uniforms.uBuffer3Progress.value !== 0)
    {
      this.bufferShader3.render(this._camera);
      this.uniforms.iChannel3.value = this.bufferShader3.readBuffer.texture;
    }

    super.render();
  }
}